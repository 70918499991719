import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import Nav from './nav';
import Footer from './footer';
import CookieBanner from './cookieBanner';
import GlobalStyle from '../styles/global';
import { colors } from '../styles/constants';

const LayoutContainer = styled.main`
  background-color: ${colors.lightGrey};
  ${(props) =>
    props.padTop &&
    css`
      padding-top: 3.75rem;
    `}
`;

function Layout({ Header, padTop = false, children }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyle />
      <Nav siteTitle={data.site.siteMetadata.title} />
      {Header}
      <LayoutContainer id="content" padTop={padTop}>
        {children}
      </LayoutContainer>
      <Footer />
      <CookieBanner />
    </>
  );
}

Layout.propTypes = {
  Header: PropTypes.node,
  padTop: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Layout;
