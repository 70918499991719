import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Instagram from '../assets/svg/instagram.svg';
import LinkedIn from '../assets/svg/linkedin.svg';
import Mail from '../assets/svg/mail.svg';
import YouTube from '../assets/svg/youtube.svg';
import { colors } from '../styles/constants';

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding-top: 0.25rem;
    margin-right: 1rem;
    color: ${colors.blue};
    transition: all 150ms ease-in-out;

    &:focus,
    &:hover {
      color: ${colors.lightBlue};
      transform: translateY(-0.1rem);
    }
  }
`;

function Social() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          linkedInUrl
          instagramUrl
          mail
          youtubeUrl
        }
      }
    }
  `);

  return (
    <SocialContainer>
      <a
        href={data.site.siteMetadata.linkedInUrl}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Azitek on LinkedIn"
      >
        <LinkedIn />
      </a>
      <a
        href={data.site.siteMetadata.youtubeUrl}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Azitek on YouTube"
      >
        <YouTube />
      </a>
      <a
        href={data.site.siteMetadata.instagramUrl}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Azitek on U=Instagram"
      >
        <Instagram />
      </a>
      <a href={`mailto:${data.site.siteMetadata.mail}`} aria-label="Mail Us">
        <Mail />
      </a>
      © Azitek {new Date().getFullYear()}
    </SocialContainer>
  );
}

export default Social;
