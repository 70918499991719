import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { colors, media } from '../styles/constants';

const MenuButton = styled.button`
  border: none;
  padding: 0;
  margin-left: auto;
  background: transparent;
  position: relative;

  transition-property: transform, opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  @media (min-width: ${media.navMaxLength}px) {
    display: none;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  span {
    width: 2rem;
    height: 3px;
    border-radius: 5px;
    background-color: ${colors.blue};
    display: block;

    transition-property: transform, opacity, background-color;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;

    &:nth-child(2) {
      margin: 0.25rem 0;
    }
  }

  ${(props) =>
    props.active &&
    css`
      transform: rotate(45deg);

      span {
        width: 2rem;
        margin: 0 auto;

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: translateY(6px);
        }

        &:nth-child(3) {
          transform: translateY(-8px) rotate(90deg);
        }
      }
    `}
`;

const Menu = ({ isMenuOpen, onClick }) => (
  <MenuButton
    active={isMenuOpen}
    onClick={onClick}
    aria-label="Expand or collapse the mobile menu"
  >
    <span />
    <span />
    <span />
  </MenuButton>
);

Menu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Menu;
