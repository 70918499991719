import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import MenuButton from './menuButton';

import { colors, media } from '../styles/constants';
import { noVerticalPaddingStyle } from '../styles/layout';
import linkStyle from '../styles/link';

import MenuIcon from '../assets/svg/menu.svg';
import CloseIcon from '../assets/svg/x.svg';

const NavContainer = styled.nav`
  background-color: ${colors.lightGrey};
  max-height: 3.75rem;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.35);
  padding: 0.75rem 0;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;

  transition-property: max-height;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  transition-delay: ${(props) => (props.isMenuOpen ? 0 : 100)}ms;

  ${(props) =>
    props.isMenuOpen &&
    css`
      max-height: 20.25rem;
    `}

  @media (min-width: ${media.navMaxLength}px) {
    max-height: 3.75rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  @media (min-width: ${media.navMaxLength}px) {
    align-items: center;
    flex-direction: row;
  }

  ${noVerticalPaddingStyle}

  @media (min-width: ${media.desktop}px) {
    max-width: 1240px;
  }
`;

const LogoContainer = styled.div`
  display: flex;

  > a {
    width: 7rem;
    margin-right: 2rem;
    cursor: pointer;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 0 0 0.5rem;

    @media (min-width: ${media.navMaxLength}px) {
      display: none;
    }
  }

  a {
    ${linkStyle}
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;

    &::after {
      display: none;
    }
  }

  a,
  hr {
    opacity: ${(props) => (props.isMenuOpen ? 1 : 0)};
    visibility: ${(props) => (props.isMenuOpen ? 'visible' : 'hidden')};
    pointer-events: ${(props) => (props.isMenuOpen ? 'inherit' : 'none  ')};

    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    transition-delay: ${(props) => (props.isMenuOpen ? 100 : 0)}ms;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${media.navMaxLength}px) {
    hr {
      display: none;
    }

    div {
      flex-direction: row;
    }

    width: 100%;
    align-items: center;
    flex-direction: row;
    padding-top: 0;

    a {
      opacity: 1;
      margin: 0 1rem;
      visibility: visible;
      pointer-events: inherit;

      &::after {
        display: inherit;
      }
    }

    > div:last-child {
      margin-left: auto;
    }
  }
`;

function Nav() {
  const logo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-no-caption.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <NavContainer isMenuOpen={isMenuOpen}>
      <ContentContainer>
        <LogoContainer>
          <Link to="/">
            <Img
              style={{ width: '100%', height: '100%' }}
              fluid={logo.placeholderImage.childImageSharp.fluid}
              loading="eager"
            />
          </Link>
          <MenuButton
            isMenuOpen={isMenuOpen}
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </MenuButton>
        </LogoContainer>
        <LinkContainer isMenuOpen={isMenuOpen}>
          <div>
            <Link activeClassName="active" to="/">
              Home
            </Link>
            <Link activeClassName="active" to="/asset-management">
              Asset Management
            </Link>
            <Link activeClassName="active" to="/milk-run">
              Tugger Train Tracking
            </Link>
            <Link activeClassName="active" to="/use-cases">
              Use Cases
            </Link>
            <Link activeClassName="active" to="/news">
              News
            </Link>
            <Link activeClassName="active" to="/about">
              About Us
            </Link>
          </div>
          <div>
            <hr />
            <a
              href="https://dashboard.azitek.io/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Login
            </a>
          </div>
        </LinkContainer>
      </ContentContainer>
    </NavContainer>
  );
}

export default Nav;
