import styled, { css } from 'styled-components';

import { colors } from '../styles/constants';

const Button = styled.button`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 12rem;
  cursor: pointer;
  outline: none;
  background-color: ${colors.blue};
  color: ${colors.lightGrey};
  transition: all 200ms ease-in-out;

  &:hover {
    border-color: ${colors.blue};
    background-color: ${colors.white};
    color: ${colors.blue};
  }

  &:active,
  &:focus {
    border-color: ${colors.blue};
    background-color: ${colors.lightGrey};
    color: ${colors.blue};
  }

  &:disabled {
    opacity: 0.8;
    background-color: ${colors.blue};
    color: ${colors.lightGrey};
  }

  ${(props) =>
    props.secondary &&
    css`
      border-color: ${colors.lightGrey};
      background-color: transparent;

      &:hover {
        background-color: ${colors.white};
        color: ${colors.blue};
      }

      &:active,
      &:focus {
        border-color: ${colors.blue};
        background-color: ${colors.lightGrey};
        color: ${colors.blue};
      }

      &:disabled {
        opacity: 0.8;
        background-color: transparent;
        border-color: ${colors.lightGrey};
      }
    `}
`;

export default Button;
