import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';

import Button from './button';
import { colors } from '../styles/constants';
import { noVerticalPaddingStyle } from '../styles/layout';

const CookieConsentWrapper = styled.div`
  span,
  button {
    font-size: 0.75rem;
  }

  > .CookieConsent {
    display: flex;
    justify-content: unset;
    background: ${colors.blue} !important;
    right: 0;

    > div:first-child {
      flex: 0 !important;
      margin: 0 !important;
    }

    > div:last-child {
      width: 100%;
      padding: 0.325rem 0;

      > div {
        ${noVerticalPaddingStyle};
        display: flex;
        align-items: center;

        button {
          margin-left: auto;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 5rem;
            right: 0;
            bottom: 5px;
            margin: 0 auto;
            border-top: solid 1px #ffffff;
            transition: opacity 150ms ease-in-out;
            opacity: 1;
          }

          &:hover:after {
            opacity: 0;
          }
        }
      }
    }
  }
`;

function CookieBanner() {
  return (
    <CookieConsentWrapper>
      <CookieConsent
        location="bottom"
        cookieName="cookie-consent"
        expires={150}
        ButtonComponent={({ children, ...props }) => {
          delete props.style;
          return (
            <div>
              <span>
                We use cookies to give you the best and most relevant
                experience.
              </span>
              <Button {...props}>{children}</Button>
            </div>
          );
        }}
      ></CookieConsent>
    </CookieConsentWrapper>
  );
}

export default CookieBanner;
