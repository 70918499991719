import { css } from 'styled-components';
import { media } from './constants';
import { contentHeaderStyles } from './text';

export const padding = {
  base: '2rem',
  phoneLandscape: '3rem',
  tabletPortrait: '3.5rem',
  tabletLandscape: '4.5rem',
  desktop: '5rem',
  bigDesktop: '6rem'
};

export const commonStyles = css`
  margin: 0 auto;
  ${contentHeaderStyles}
`;

export const noVerticalPaddingStyle = css`
  ${commonStyles};
  padding: 0 ${padding.base};

  @media (min-width: ${media.phoneLandscape}px) {
    padding: 0 ${padding.phoneLandscape};
  }

  @media (min-width: ${media.tabletPortrait}px) {
    padding: 0 ${padding.tabletPortrait};
  }

  @media (min-width: ${media.tabletLandscape}px) {
    padding: 0 ${padding.tabletLandscape};
  }

  @media (min-width: ${media.desktop}px) {
    max-width: 1140px;
    padding: 0 ${padding.desktop};
  }

  @media (min-width: ${media.bigDesktop}px) {
    max-width: 80%;
    padding: 0 ${padding.desktop};
  }
`;

export default css`
  ${commonStyles};
  padding: ${padding.base};

  @media (min-width: ${media.phoneLandscape}px) {
    padding: ${padding.phoneLandscape};
  }

  @media (min-width: ${media.tabletPortrait}px) {
    padding: ${padding.tabletPortrait};
  }

  @media (min-width: ${media.tabletLandscape}px) {
    padding: ${padding.tabletLandscape};
  }

  @media (min-width: ${media.desktop}px) {
    max-width: 1140px;
    padding: ${padding.desktop};
  }

  @media (min-width: ${media.bigDesktop}px) {
    max-width: 80%;
    padding: ${padding.desktop};
  }
`;
