import { createGlobalStyle } from 'styled-components';
import { colors } from './constants';
import { text } from './text';

export default createGlobalStyle`
  /*
    BEGIN CSS RESET
    SOURCE: https://meyerweb.com/eric/tools/css/reset/
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* END CSS RESET */

  html {
    font-family: 'Karla', 'Rubik', sans-serif;
    font-size: 16px;
    overflow-y: scroll;
  }

  body {
    color: ${colors.black};
    margin: 0;
    line-height: 1;
    background-color: ${colors.lightGrey};
    position: relative;
    overflow-x: hidden;
  }

  /* IE */
  div[role="group"][tabindex] {
    overflow: hidden;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: bold;
  }

  p, span, a, button, input, textarea {
    ${text}
  }

  input {
    border: solid 1px ${colors.blue};
  }

  button, input, textarea {
    font-family: 'Karla', sans-serif;
  }

  address {
    font-style: normal;
  }

  a {
    text-decoration: none;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
`;
