import { css } from 'styled-components';

import { colors } from './constants';

export default css`
  text-decoration: none;
  position: relative;
  color: ${colors.blue};
  transition: color 150ms ease-in-out;

  &:after {
    opacity: 0;
    position: absolute;
    content: '';
    width: 100%;
    top: 1.5rem;
    left: 0;
    height: 2px;
    background-color: ${colors.lightBlue};
    transition: opacity 150ms ease-in-out;
  }

  &.active {
    &:after {
      background-color: ${colors.blue};
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    color: ${colors.lightBlue};

    &:after {
      background-color: ${colors.lightBlue};
      opacity: 1;
    }
  }
`;
