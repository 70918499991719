import { css } from 'styled-components';
import { media } from './constants';

export const big = css`
  font-weight: 600;
  font-size: 3rem;

  @media (min-width: ${media.tabletPortrait}px) {
    font-size: 8rem;
  }
  @media (min-width: ${media.tabletLandscape}px) {
    font-size: 10rem;
  }
  @media (min-width: ${media.desktop}px) {
    font-size: 12rem;
  }
`;

export const small = css`
  font-weight: 500;
  font-size: 1rem;
  line-height: 2.4rem;
`;

export const h1 = css`
  font-weight: 600;
  font-size: 4rem;
  line-height: 4.6rem;

  @media (min-width: ${media.tabletPortrait}px) {
    font-size: 5.8rem;
    line-height: 7.2rem;
  }
  @media (min-width: ${media.tabletLandscape}px) {
    font-size: 6.8rem;
    line-height: 9rem;
  }
  @media (min-width: ${media.desktop}px) {
    font-size: 6.8rem;
    line-height: 9rem;
  }
  @media (min-width: ${media.bigDesktop}px) {
    font-size: 7.8rem;
    line-height: 10rem;
  }
`;

export const h2 = css`
  font-weight: 600;

  font-size: 3rem;
  line-height: 3.9rem;

  @media (min-width: ${media.tabletLandscape}px) {
    font-size: 3.4rem;
    line-height: 4.5rem;
  }
`;

export const text = css`
  font-size: 1.2rem;
  line-height: 1.5;
`;

export const contentHeaderStyles = css`
  h1 {
    letter-spacing: 0.5px;
    font-weight: 700;
    font-size: 2.5rem;
    padding-bottom: 2.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      font-size: 3.25rem;
      padding-bottom: 3rem;
    }
  }

  h2 {
    letter-spacing: 0.5px;
    font-size: 1.375rem;
    padding-bottom: 2.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      font-size: 1.75rem;
      padding-bottom: 3rem;
    }
  }
`;
