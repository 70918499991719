export const colors = {
  lightBlue: '#69a8cc',
  blue: '#1f4258',
  lightGrey: '#f8f8f8',
  white: '#ffffff',
  background: '#f3f3f3',
  grey: '#9f9f9f',
  black: 'black'
};

export const media = {
  phonePortrait: 390,
  phoneLandscape: 576,
  tabletPortrait: 768,
  tabletLandscape: 992,
  navMaxLength: 1185,
  newsTextWidth: 1050,
  desktop: 1200,
  newsLength: 1400,
  bigDesktop: 1800
};
